<!-- 法律申明 -->
<template>
  <div class="about-outer about-law clearfix auto">
    <div class="about-main-container">
      <div class="law-part-div border-bottom-1">
        <p class="ab-part-title">法律声明</p>
        <p class="ab-part-law-intro margin-bottom-26">
          企业服务平台提醒您：在使用i360.cn各项服务前，请您务必仔细阅读并透彻理解本声明。
        </p>
        <p class="ab-part-law-intro margin-bottom-26">
          您可以选择不使用i360.cn服务，但如果您使用i360.cn服务的，您的使用行为将被视为对本声明全部内容的认可。“i360.cn”指由泛亚企业服务平台（简称“企业服务平台”）运营的网站，域名为i360.cn。鉴于企业服务平台提供的部分服务属于电子公告牌服务，i360.cn上关于i360.cn会员或其发布的相关信息（包括但不限于用户名称、公司名称、
          联系人及联络信息，相关图片、视讯等）的信息均是由会员自行提供，会员依法应对其提供的任何信息承担全部责任。任何单位或个人认为i360.cn网页内容（包括但不限于i360.cn会员发布的商品信息）可能涉嫌侵犯其合法权益，应该及时向企业服务平台提出书面权利通知，并提供身份证明、权属证明、具体链接（URL）及详细侵权情况证明。企业服务平台在收到上述法律文件后，将会依法尽快移除相关涉嫌侵权的内容。i360.cn转载作品（包括论坛内容）出于传递更多信息之目的，并不意味企业服务平台（包括企业服务平台关联企业）赞同其观点或证实其内容的真实性。
        </p>
      </div>
      <div class="law-part-div border-bottom-1">
        <p class="ab-part-title">知识产权申明</p>
        <p class="ab-part-law-intro margin-bottom-26">
          企业服务平台拥有i360.cn网站内所有信息内容（除i360.cn会员发布的商品信息外，包括但不限于文字、图片、软件、音频、视频）的版权。被授权的浏览、复制、打印和传播属于i360.cn网站内信息内容都不得用于商业目的且所有信息内容及其任何部分的使用都必须包括此版权声明；i360.cn所有的产品、技术与所有程序均属于企业服务平台的知识产权。“企业服务平台”、企业服务平台其他产品服务名称及相关图形、标识等为企业服务平台的注册商标。未经企业服务平台许可，任何人不得擅自（包括但不限于：以非法的方式复制、传播、展示、镜像、上载、下载）使用。否则，企业服务平台将依法追究法律责任。
        </p>
      </div>
      <div class="law-part-div">
        <p class="ab-part-title">隐私权政策</p>
        <p class="ab-part-law-intro">
          企业服务平台尊重并保护所有使用i360.cn服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，企业服务平台会按照本隐私权政策的规定使用和披露您的个人信息。但企业服务平台将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，企业服务平台不会将这些信息对外披露或向第三方提供。
          企业服务平台会不时更新本隐私权政策。
          您在同意企业服务平台服务协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于企业服务平台服务协议不可分割的一部分。
        </p>
        <p class="ab-part-law-intro color-blue">1、适用范围</p>
        <p class="ab-part-law-intro">
          a)
          在您注册企业服务平台账户时，您根据企业服务平台要求提供的个人或组织注册信息；
        </p>
        <p class="ab-part-law-intro">
          b)
          在您使用i360.cn服务，或访问i360.cn网页时，企业服务平台自动接收并记录的您的浏览器和计算机上的信息，
          包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；
        </p>
        <p class="ab-part-law-intro">
          c) 企业服务平台通过合法途径从商业伙伴处取得的用户个人数据。
        </p>
        <p class="ab-part-law-intro">
          您了解并同意，以下信息不适用本隐私权政策：
        </p>
        <p class="ab-part-law-intro">
          a) 您在使用i360.cn提供的BBS服务时所发布的信息；
        </p>
        <p class="ab-part-law-intro">
          b) 您在使用i360.cn提供的服务时，对外公布的信息；
        </p>
        <p class="ab-part-law-intro">
          c)
          信用评价、违反法律规定或违反企业服务平台规则行为及企业服务平台已对您采取的措施
        </p>
        <p class="ab-part-law-intro color-blue">2、信息使用</p>
        <p class="ab-part-law-intro">
          a)
          企业服务平台不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和企业服务平台（含企业服务平台关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料；
        </p>
        <p class="ab-part-law-intro">
          b)
          企业服务平台亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何i360.cn用户如从事上述活动，一经发现，企业服务平台有权立即终止与该用户的服务协议；
        </p>
        <p class="ab-part-law-intro">
          c)
          为服务用户的目的，企业服务平台可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与企业服务平台合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。
        </p>
        <p class="ab-part-law-intro color-blue">3、信息披露</p>
        <p class="ab-part-law-intro">
          在如下情况下，企业服务平台将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：
        </p>
        <p class="ab-part-law-intro">a) 经您事先同意，向第三方披露；</p>
        <p class="ab-part-law-intro">
          b)
          如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；
        </p>
        <p class="ab-part-law-intro">
          c)
          根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
        </p>
        <p class="ab-part-law-intro">
          d)
          如您出现违反中国有关法律、法规或者企业服务平台服务协议或相关规则的情况，需要向第三方披露；
        </p>
        <p class="ab-part-law-intro">
          e) 为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；
        </p>
        <p class="ab-part-law-intro">
          f)
          在i360.cn上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，企业服务平台有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决；
        </p>
        <p class="ab-part-law-intro">
          g) 其它企业服务平台根据法律、法规或者网站政策认为合适的披露。
        </p>
        <p class="ab-part-law-intro color-blue">4、信息存储和交换</p>
        <p class="ab-part-law-intro">
          企业服务平台收集的有关您的信息和资料将保存在企业服务平台及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或企业服务平台收集信息和资料所在地的境外并在境外被访问、存储和展示。
        </p>
        <p class="ab-part-law-intro color-blue">5. Cookie的使用</p>
        <p class="ab-part-law-intro">
          a)
          在您未拒绝接受cookies的情况下，企业服务平台会在您的计算机上设定或取用cookies，以便您能登录或使用依赖于cookies的i360.cn服务或功能。企业服务平台使用cookies可为您提供更加周到的个性化服务，包括推广服务；
        </p>
        <p class="ab-part-law-intro">
          b)
          您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的i360.cn服务或功能；
        </p>
        <p class="ab-part-law-intro">
          c) 通过企业服务平台所设cookies所取得的有关信息，将适用本政策。
        </p>
        <p class="ab-part-law-intro color-blue">6. 信息安全</p>
        <p class="ab-part-law-intro">
          a)
          企业服务平台及支付宝账户均有安全保护功能，请妥善保管您的账户及密码信息。企业服务平台将通过向其它服务器备份、对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在绝对完善的安全措施；
        </p>
        <p class="ab-part-law-intro">
          b)
          在使用i360.cn服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是企业服务平台账户及密码发生泄露，请您立即联络企业服务平台客服，以便企业服务平台采取相应措施。
        </p>
        <p class="ab-part-law-intro color-blue">7. 未成年人的特别注意事项</p>
        <p class="ab-part-law-intro">
          如果您不是具备完全民事权利能力和完全民事行为能力的自然人，您无权使用i360.cn服务，因此企业服务平台希望您不要向我们提供任何个人信息。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
header .top-header {
  position: relative;
}
@import "../../../assets/static/template/Home/Zkeys/PC/Static/css/module/about/law.css";
</style>
